import { useCallback, useEffect } from 'react';
import { useGlobalContext, useSearchRateContext } from '@/services/contexts';
import clsx from 'clsx';
import { t } from 'i18next';
import _ from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { MapBox } from '../components/mapbox';
import { TerminalIcon } from '../components/terminal-icon';
import { Fees } from './components/fees';
import { PossibleCharges } from './components/possible-charges';
import { Recommended } from './components/recommended';
import SearchResultForm from './components/search-result-form';
import CenterSpin from '@/components/center-spin';
import useSearchRatesParams from '../hooks/use-search-rates-params';
import { OnSearchFormFinish } from '../interface';

interface SearchRateResultProps {
	onFinish: OnSearchFormFinish;
}

export const SearchRateResult = observer(({ onFinish }: SearchRateResultProps) => {
	const { screenContext } = useGlobalContext();
	const searchRateContext = useSearchRateContext();

	const { cleanedSearchParams, isSearchRatesParamsValid } = useSearchRatesParams();

	const init = useCallback(async () => {
		searchRateContext.clearState();
		onFinish(cleanedSearchParams, { disableNavigate: true });
	}, [cleanedSearchParams, isSearchRatesParamsValid]);

	const to = searchRateContext.selectedSearchData?.to_city ?? searchRateContext.selectedToCity;
	const start =
		searchRateContext.selectedSearchData?.terminals?.[0] ??
		searchRateContext.selectedSearchData?.intermodal_region ??
		searchRateContext.selectedIntermodalRegion;

	const getOtherPoints = () => {
		if (!searchRateContext?.selectedIntermodalRegion) return [];

		if (searchRateContext.searchDatas) {
			const unselected =
				searchRateContext.searchDatas
					?.filter((d) => d.id !== searchRateContext.selectedSearchData?.id)
					?.map((d) => d.terminals?.[0])
					?.filter((t) => t)
					?.map((t) => ({
						...t,
						icon: <TerminalIcon facility_type={t.facility_type} isActive={false} />,
						popup: t.full_address,
					})) ?? [];
			return unselected;
		}

		if (searchRateContext.selectedIntermodalRegion.terminals) {
			const IRTerminalPoints = searchRateContext.selectedIntermodalRegion.terminals.map((t) => ({
				...t,
				icon: <TerminalIcon facility_type={t.facility_type} isActive={false} />,
			}));
			return IRTerminalPoints;
		}

		return [];
	};

	useEffect(() => {
		init();
	}, [cleanedSearchParams]);

	if (searchRateContext.isFetching)
		return (
			<div className='flex justify-center items-center w-full h-screen'>
				<CenterSpin size='large' />
			</div>
		);

	return (
		<div
			className={clsx(
				' box-border w-full pt-[36px] pl-[48px] pr-[48px] pb-[8px] bg-[#ffffff]',
				screenContext.isScreenShot ? 'h-max' : 'h-screen',
			)}
		>
			<div className={clsx('flex flex-col', screenContext.isScreenShot ? 'h-max' : 'h-full')}>
				{/* search bar */}
				<div className=' box-content flex-shrink-0 mb-[36px] h-[64px] border-[1px] border-solid border-[#C8C8C8] rounded-[12px] overflow-x-hidden'>
					<SearchResultForm onFinish={onFinish} />
				</div>

				{/* content */}
				<div
					className={clsx(
						'flex flex-shrink flex-grow gap-[16px]  ',
						screenContext.isScreenShot ? 'h-max' : 'h-[100px]',
					)}
				>
					{/* left */}
					<div className='flex flex-col flex-grow gap-[16px]'>
						{/* map */}
						<div className='h-[304px]  rounded-[10px] overflow-hidden'>
							<MapBox
								components={{ zoom: true, distance: true }}
								start={
									start
										? {
												...start,
												// @ts-ignore
												icon: <TerminalIcon facility_type={start?.facility_type} isActive />,
										  }
										: start
								}
								to={to}
								otherPoints={getOtherPoints()}
							/>
						</div>
						{/* select */}
						<div
							className={clsx('flex-grow bg-[#E6F0FA] ', {
								'overflow-y-scroll': !screenContext.isScreenShot,
								'h-1': !screenContext.isScreenShot,
								'h-fit': screenContext.isScreenShot,
							})}
						>
							<Fees />
						</div>
					</div>
					{/* right */}
					<div className='flex flex-col w-[400px] gap-[16px]'>
						{/* recommend */}
						<div className='flex-grow-0  pb-[3px] rounded-[10px] overflow-hidden'>
							<Recommended />
						</div>
						{/* charges */}
						<div className={clsx('flex-grow h-[100px]', { 'h-max': screenContext.isScreenShot })}>
							<PossibleCharges
								possible_charges={searchRateContext?.selectedSearchData?.possible_charges}
								rate={searchRateContext?.selectedSearchData?.truck_rate}
								loading={searchRateContext.isFetching}
								emptyDescription={t('Please select a terminal to check accessorial fees')}
								showProbability
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});
